import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppBasePreloader implements PreloadingStrategy {
  constructor() {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    // tslint:disable-next-line:no-string-literal
    const shouldPreload = route.data && route.data['shouldPreload'];

    if (shouldPreload === true) {
      return load();
    } else {
      return of(null);
    }
  }
}
