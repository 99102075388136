import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { AuthException, AuthExceptionCode } from './auth_exception';
import firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Intercom } from 'ng-intercom';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import * as Sentry from '@sentry/angular';

export interface AuthenticatedUser {
  id: string;
  email: string;
  emailVerified: boolean;
  jwt: string;
  isAnonymous: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authenticatedUserBS = new BehaviorSubject<AuthenticatedUser>({} as AuthenticatedUser);

  public firebaseUser: firebase.User = {} as firebase.User;

  constructor(
    protected firebaseAuth: AngularFireAuth,
    private _firebaseAnalytics: AngularFireAnalytics,
    private intercom: Intercom,
    protected http: HttpClient,
  ) {
    this._subscriptToAuthState();
  }

  private _subscriptToAuthState() {

    this.firebaseAuth.authState.subscribe(async (user) => {

      if (user == null) {
        this.authenticatedUserBS.next({} as AuthenticatedUser);
      } else {
        await this._processFirebaseUser(user);
      }
    });

  }

  async createUserAndLinkCurrentAutUser(email: string, password: string) {
    const cred = firebase.auth.EmailAuthProvider.credential(email, password);

    let userCred = {} as firebase.auth.UserCredential;
    try {
      userCred = await this.firebaseUser.linkWithCredential(cred);
    } catch (e) {
      throw e;
    }

    try {
      await this._processFirebaseUser(userCred.user as firebase.User);
    } catch (e) {
      throw e;
    }
  }

  async createUser(email: string, password: string): Promise<AuthenticatedUser> {
    const authUser = await this.firebaseAuth.createUserWithEmailAndPassword(email, password);
    const authenticatedUser = await this._processFirebaseUser(authUser.user as firebase.User);

    await this._processFirebaseUser(authUser.user as firebase.User);

    return authenticatedUser;

  }

  async login(email: string, password: string) {

    let authUser;
    try {
      authUser = await this.firebaseAuth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      throw this._processLoginError(e);
    }

    const authenticatedUser = await this._processFirebaseUser(authUser.user as firebase.User);
    return authenticatedUser;
  }

  async getAuthUser() {
    return this.firebaseAuth.currentUser;
  }

  async logout() {
    await this.firebaseAuth.signOut();
    await this._firebaseAnalytics.setUserId('');
  }

  private async _processFirebaseUser(user: firebase.User): Promise<AuthenticatedUser> {
    const authenticatedUser = {} as AuthenticatedUser;

    this.firebaseUser = user;

    authenticatedUser.id = user.uid;
    authenticatedUser.email = user.email as string;
    authenticatedUser.emailVerified = user.emailVerified;
    authenticatedUser.jwt = await user.getIdToken();

    authenticatedUser.isAnonymous = user.isAnonymous;

    if (!authenticatedUser.isAnonymous) {
      await this._firebaseAnalytics.setUserId(user.uid);
    }

    this.intercom.update({
      user_id: user.uid,
      email: authenticatedUser.email,
    });

    this.authenticatedUserBS.next(authenticatedUser);

    return authenticatedUser;
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await this.firebaseAuth.sendPasswordResetEmail(email);
    } catch (e) {
      throw e;
    }
  }

  async updatePassword(password: string) {
    try {
      await this.firebaseUser.updatePassword(password);
    } catch (e) {
      Sentry.captureException(`Account linking error ${e}`);
    }
  }

  async isEmailInUse(email: string): Promise<boolean> {
    const signinMethods = await this.firebaseAuth.fetchSignInMethodsForEmail(email);

    if (signinMethods.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  signInAnonymously() {
    return this.firebaseAuth.signInAnonymously();
  }

  async signInWithCustomToken(token: string) {
    await this.firebaseAuth.signInWithCustomToken(token);
  }

  async exchangeToken(token: string): Promise<string> {

    const jwt = `Bearer ${token}`;
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', jwt);

    const url = `${environment.systemApi.baseURL}${environment.systemApi.paths.cloudCheck.tokenExchangePath}`;

    const resp = await lastValueFrom(this.http.get(url, { headers: httpHeaders })) as { token: string };

    return resp['token'];
  }


  private _processLoginError(e: any): AuthException | Error {

    const authException = new AuthException();

    if (e.hasOwnProperty('code')) {

      switch (e.code) {
        case 'auth/invalid-email':
          authException.type = AuthExceptionCode.invalidEmail;
          break;

        case 'auth/user-disabled':
          authException.type = AuthExceptionCode.userDisabled;
          break;

        case 'auth/user-not-found':
          authException.type = AuthExceptionCode.userNotFound;
          break;

        case 'auth/wrong-password':
          authException.type = AuthExceptionCode.wrongPassword;
          break;

        case 'auth/invalid-action-code':
          authException.type = AuthExceptionCode.invalidActionCode;
          break;

        case 'auth/expired-action-code':
          authException.type = AuthExceptionCode.expiredActionCode;
          break;

        default:
          console.error('cannot handle:', e.code);
      }

      return authException;

    } else {
      return e;
    }

  }

}
