<div class="component-container" ngClass.md="tablet" fxLayout="column">
  <div class="header" fxLayout="row" fxLayoutAlign="center center">
    <a href="/kiwisaver/onboarding">
      <img id="logo" alt="BetterSaver Logo" src="../../assets/images/cache/BetterSaver.svg">
    </a>

  </div>

  <div class="heading-container">
    <h1>404</h1>

    <p>Page Not Found</p>

    <a class="btn-return" href="/">Eep! Take me back home!</a>
  </div>
</div>