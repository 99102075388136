import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, QueryFn } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { NotFoundException } from './not-found-exception';
import { take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;


@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(private afs: AngularFirestore) { }

  get generateId() {
    return this.afs.createId();
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  col<T>(ref: CollectionPredicate<T>, queryFn?: QueryFn): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /**
   * Get document once with ids
   * @param ref Document reference
   * @returns One document
   */
  async docOnce<T>(ref: DocPredicate<T>): Promise<T> {

    const refDoc = this.doc(ref);

    let doc: firebase.firestore.DocumentSnapshot;

    doc = await lastValueFrom(refDoc.get());

    if (doc.exists) {
      const data = doc.data();
      if (typeof data === 'undefined') {
        throw new NotFoundException(doc.ref.path);
      }
      data['id'] = doc.id;
      return data as T;
    } else {
      throw new NotFoundException(doc.ref.path);
    }

  }

  set<T>(ref: DocPredicate<T>, data: any) {
    const timestamp = this.timestamp;
    const cleanedData = this._cleanServerReadonlyData(data);
    return this.doc(ref).set({
      ...cleanedData,
      updated_at: timestamp,
      created_at: timestamp
    });
  }

  private _cleanServerReadonlyData(data: any): any {
    const newData: any = {};

    for (const key of Object.keys(data)) {
      if (key.indexOf('sv_') === -1) {
        newData[key] = data[key];
      }
    }

    return newData;
  }

  update<T>(ref: DocPredicate<T>, data: any) {
    const timestamp = this.timestamp;
    const cleanedData = this._cleanServerReadonlyData(data);

    return this.doc(ref).update({
      ...cleanedData,
      updated_at: timestamp
    });
  }

  /**
   * If doc exists update, otherwise set
   * @param ref Document ref
   * @param data Document data
   */
  async upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = await lastValueFrom(this.doc(ref).get().pipe(take(1)));
    return doc.exists ? this.update(ref, data) : this.set(ref, data);
  }

}
