import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreService } from '../firestore.service';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';
import { Intercom } from 'ng-intercom';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { EthicsExposureDecision } from '../profile/profile-ethics.service';

export interface OnboardingData {
  id: string;
  account_id: string;
  person_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  dob: string;
  goal: string;
  goal_by_when: string;
  investing_knowledge: string;
  concern: string;
  value_drop: string;
  investment_summary: string;
  sustainability: string;
  ethics_animal_testing: EthicsExposureDecision;
  ethics_weapons_of_war: EthicsExposureDecision;
  ethics_adult_entertainment: EthicsExposureDecision;
  ethics_tobacco: EthicsExposureDecision;
  ethics_alcohol: EthicsExposureDecision;
  ethics_gambling: EthicsExposureDecision;
  ethics_civilian_weapons: EthicsExposureDecision;
  ethics_nuclear_power: EthicsExposureDecision;
  last_page_path: string;
  complete: boolean;
  created_at: Date;
  updated_at: Date;
  invite_id: string;
  sv_intercom_id: string;
  signupcomplete: boolean;
  address_l1: string;
  address_l2: string;
  post_code: string;
  city: string;
  ird_number: string;
  pir: string;
  fund_id: string;
  license_number: string;
  license_version: string;
  license_expiry: string;
  passport_number: string;
  passport_expiry: string;
  cloud_check_capture_reference: string;
  window_height: number;
  window_width: number;
  shown_recommendation_screen: boolean;
  recommended_funds: string[];
  identity_interaction: boolean;
  see_my_fund_button: boolean;
  see_my_fund_button_clicked: boolean;
  referer_id: string;
  hear_about_us: string;
  signup_error: string;
  created_by: string; // Who this user was created by in admin area
  milford_accepted: boolean;
  promo_code: string;
}


@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  onboardingData: OnboardingData = {} as OnboardingData;
  private _onboardingId: string = '';

  onBoardingPath = 'Onboarding';
  private col: AngularFirestoreCollection;

  constructor(
    private firestore: FirestoreService,
    private _authService: AuthService,
    private _intercom: Intercom,
    private _http: HttpClient,
  ) {
    this.col = this.firestore.col(this.onBoardingPath);
  }

  async initNewOnboardingData(onBoardingId: string) {

    this.onboardingData = {} as OnboardingData;
    this.onboardingData.complete = false;
    this.onboardingData.shown_recommendation_screen = false;
    this.onboardingData.signupcomplete = false;
    this.onboardingData.id = onBoardingId;


    this.onboardingId = onBoardingId;
    this.col = this.firestore.col(this.onBoardingPath);

    this.onboardingData.window_height = window.innerHeight;
    this.onboardingData.window_width = window.innerWidth;

    return this.create(onBoardingId);

  }

  get onboardingDoc() {
    // The onboaring id is the AUTH USER ID
    return this.col.doc(this.onboardingId);
  }

  get onboardingId(): string {
    return this._onboardingId;
  }

  set onboardingId(value: string) {
    this._onboardingId = value;
    this.onboardingData.id = value;
  }

  set shownRecommendationScreen(value: boolean) {
    this.onboardingData.shown_recommendation_screen = value;
  }

  get recommended_funds() {
    return this.onboardingData.recommended_funds;
  }

  set recommended_funds(value: string[]) {
    this.onboardingData.recommended_funds = value;
  }

  async updateIntercomData() {

    if (!environment.production) {
      console.log('intercom user', this._intercom.getVisitorId());
    }

    const data = {} as any;
    // build up data as it's passed - onboarding
    if (typeof this.onboardingData.goal !== 'undefined') {
      data['OB Goal'] = this.onboardingData.goal;
    }
    if (typeof this.onboardingData.goal_by_when !== 'undefined') {
      data['OB Goal By When'] = this.onboardingData.goal_by_when;
    }
    if (typeof this.onboardingData.investing_knowledge !== 'undefined') {
      data['OB Investing Knowledge'] = this.onboardingData.investing_knowledge;
    }
    if (typeof this.onboardingData.investment_summary !== 'undefined' && this.onboardingData.investment_summary !== '') {
      data['OB Investing Summary'] = this.onboardingData.investment_summary;
    }
    if (typeof this.onboardingData.concern !== 'undefined') {
      data['OB Personality Concern'] = this.onboardingData.concern;
    }
    if (typeof this.onboardingData.value_drop !== 'undefined') {
      data['OB Personality Value Drop'] = this.onboardingData.value_drop;
    }
    if (typeof this.onboardingData.sustainability !== 'undefined') {
      data['OB Sustainability'] = this.onboardingData.sustainability;
    }
    if (typeof this.onboardingData.ethics_nuclear_power !== 'undefined') {
      data['OB Ethics Nuc Power'] = this.onboardingData.ethics_nuclear_power;
    }
    if (typeof this.onboardingData.ethics_animal_testing !== 'undefined') {
      data['OB Ethics Animal Testing'] = this.onboardingData.ethics_animal_testing;
    }
    if (typeof this.onboardingData.ethics_weapons_of_war !== 'undefined') {
      data['OB Ethics Weapons Of War'] = this.onboardingData.ethics_weapons_of_war;
    }
    if (typeof this.onboardingData.ethics_adult_entertainment !== 'undefined') {
      data['OB Adult Entertainment'] = this.onboardingData.ethics_adult_entertainment;
    }
    if (typeof this.onboardingData.ethics_tobacco !== 'undefined') {
      data['OB Tobacco'] = this.onboardingData.ethics_tobacco;
    }
    if (typeof this.onboardingData.ethics_alcohol !== 'undefined') {
      data['OB Ethics Alcohol'] = this.onboardingData.ethics_alcohol;
    }
    if (typeof this.onboardingData.ethics_civilian_weapons !== 'undefined') {
      data['OB Ethics Civ Weapons'] = this.onboardingData.ethics_civilian_weapons;
    }
    if (typeof this.onboardingData.ethics_gambling !== 'undefined') {
      data['OB Ethics Gambling'] = this.onboardingData.ethics_gambling;
    }
    if (typeof this.onboardingData.last_page_path !== 'undefined') {
      data['OB Last Page'] = this.onboardingData.last_page_path;
    }
    if (typeof this.onboardingData.promo_code !== 'undefined') {
      data['OB Promo Code'] = this.onboardingData.promo_code;
    }


    // todo: move this to another atrbute
    if (typeof this.onboardingData.shown_recommendation_screen === 'undefined') {
      data['OB Complete'] = false;
    } else {
      data['OB Complete'] = this.onboardingData.shown_recommendation_screen;
    }

    if (typeof this.onboardingData.recommended_funds !== 'undefined') {
      // Intercom doesn't accept arrays - pass in stringified array instead
      data['OB Recommended Funds'] = JSON.stringify(this.onboardingData.recommended_funds);
    }

    data.email = this.onboardingData.email;

    if (typeof this.onboardingData.first_name !== 'undefined') {
      data.name = `${this.onboardingData.first_name}`;
    }

    if (typeof this.onboardingData.last_name !== 'undefined') {
      data.name = `${data.name} ${this.onboardingData.middle_name} ${this.onboardingData.last_name}`;
    }

    if (typeof this.onboardingData.address_l1 !== 'undefined') {
      data['SUD Address L1'] = this.onboardingData.address_l1;
    }
    if (typeof this.onboardingData.address_l2 !== 'undefined') {
      data['SUD Address L2'] = this.onboardingData.address_l2;
    }
    if (typeof this.onboardingData.city !== 'undefined') {
      data['SUD City'] = this.onboardingData.city;
    }
    if (typeof this.onboardingData.post_code !== 'undefined') {
      data['SUD Postcode'] = this.onboardingData.post_code;
    }
    if (typeof this.onboardingData.ird_number !== 'undefined') {
      data['SUD IRD Number'] = this.onboardingData.ird_number;
    }
    if (typeof this.onboardingData.phone !== 'undefined') {
      data.phone = this.onboardingData.phone;
    }

    if (typeof this.onboardingData.pir !== 'undefined') {
      data['SUD Pir'] = this.onboardingData.pir;
    }
    if (typeof this.onboardingData.dob !== 'undefined') {
      data['SUD Dob'] = this.onboardingData.dob;
    }
    if (typeof this.onboardingData.fund_id !== 'undefined') {
      data['SUD Fund ID'] = this.onboardingData.fund_id;
    }
    if (typeof this.onboardingData.license_number !== 'undefined') {
      data['SUD License Number'] = this.onboardingData.license_number;
    }
    if (typeof this.onboardingData.license_version !== 'undefined') {
      data['SUD License Version'] = this.onboardingData.license_version;
    }
    if (typeof this.onboardingData.license_expiry !== 'undefined') {
      data['SUD License Expiry'] = this.onboardingData.license_expiry;
    }
    if (typeof this.onboardingData.passport_number !== 'undefined') {
      data['SUD Passport Number'] = this.onboardingData.passport_number;
    }
    if (typeof this.onboardingData.passport_expiry!== 'undefined') {
      data['SUD Passport Expiry'] = this.onboardingData.passport_expiry;
    }
    if (typeof this.onboardingData.window_height !== 'undefined') {
      data['SUD Window Height'] = this.onboardingData.window_height;
    }
    if (typeof this.onboardingData.window_width !== 'undefined') {
      data['SUD Window Width'] = this.onboardingData.window_width;
    }
    if (typeof this.onboardingData.identity_interaction !== 'undefined') {
      data['SUD Identity Interaction'] = this.onboardingData.identity_interaction;
    }
    if (typeof this.onboardingData.see_my_fund_button !== 'undefined') {
      data['SUD See My Fund Button'] = this.onboardingData.see_my_fund_button;
    }
    if (typeof this.onboardingData.see_my_fund_button_clicked !== 'undefined') {
      data['SUD See My Fund Button'] = this.onboardingData.see_my_fund_button_clicked;
    }

    if (typeof this.onboardingData.referer_id !== 'undefined') {
      data['Referer Id'] = this.onboardingData.referer_id;
    }

    // set this so we can look it up from the back end
    data['Authentication ID'] = this.onboardingData.id;
    data['OB Created At'] = this.onboardingData.created_at;
    data['OB Updated At'] = this.onboardingData.updated_at;

    if (!environment.production) {
      console.log('intercom update payload', data);
    }
    this._intercom.update(data);

    // offical way
    const url = environment.systemApi.baseURL + environment.systemApi.paths.intercom.contactUpdatePath;

    const jwt = `Bearer ${this._authService.authenticatedUserBS.value.jwt}`;
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', jwt);

    data.ID = this.onboardingData.id;
    try {
      const resp = await this._http.post(url, data, { headers: httpHeaders }).toPromise();
    } catch (e: any) {
      if (e instanceof HttpErrorResponse) {
        if (e.status === 504 || e.status === 500) {
          // ignore timeout or not created yet
        } else if (e.status !== 200) {
          Sentry.captureMessage(`intercom error: ${e}`);
        }
      } else {
        Sentry.captureException(`intercom error ${JSON.stringify(e)}`);
      }
    }
  }

  async deleteOnboardingData(onboardingId: string) {
    await this.col.doc(onboardingId).delete();
    this.onboardingData = {} as OnboardingData;
  }

  async loadOnboardingData(onboardingId: string): Promise<OnboardingData> {
    try {
      const data = await this.getOne(onboardingId);
      this.onboardingId = onboardingId;
      this.onboardingData = data;
      return data;
    } catch (e) {
      throw e;
    }
  }

  async getOne(onboardingId: string): Promise<OnboardingData> {
    const ref = this.firestore.col<OnboardingData>(this.onBoardingPath).doc<OnboardingData>(onboardingId);
    return this.firestore.docOnce(ref);
  }

  get accountId() {
    return this.onboardingData.account_id;
  }

  set accountId(value: string) {
    this.onboardingData.account_id = value;
  }

  get personId() {
    return this.onboardingData.person_id;
  }

  set personId(value: string) {
    this.onboardingData.person_id = value;
  }

  set refererId(value: string) {
    this.onboardingData.referer_id = value;
  }

  get refererId() {
    return this.onboardingData.referer_id;
  }

  set identityInteraction(value: boolean) {
    this.onboardingData.identity_interaction = true;
  }

  get identityInteraction() {
    return this.onboardingData.identity_interaction;
  }

  set seeMyFundButton(value: boolean) {
    this.onboardingData.see_my_fund_button = true;
  }

  get seeMyFundButton() {
    return this.onboardingData.see_my_fund_button;
  }

  set seeMyFundButtonClicked(value: boolean) {
    this.onboardingData.see_my_fund_button_clicked = true;
  }

  get seeMyFundButtonClicked() {
    return this.onboardingData.see_my_fund_button_clicked;
  }

  // phone
  set phone(value: string) {
    this.onboardingData.phone = value;
  }

  get phone(): string {
    return this.onboardingData.phone;
  }

  // FIRST NAME
  set firstName(value: string) {
    this.onboardingData.first_name = value;
  }

  get firstName(): string {
    return this.onboardingData.first_name;
  }

  // MIDDLE NAME
  set middleName(value: string) {
    this.onboardingData.middle_name = value;
  }

  get middleName(): string {
    return this.onboardingData.middle_name;
  }

  // LAST PAGE PATH
  set lastPagePath(value: string) {
    this.onboardingData.last_page_path = value;
  }

  get lastPagePath(): string {
    return this.onboardingData?.last_page_path;
  }


  // LAST NAME
  set lastName(value: string) {
    this.onboardingData.last_name = value;
  }

  get lastName(): string {
    return this.onboardingData.last_name;
  }

  // EMAIL
  set email(value: string) {
    this.onboardingData.email = value;
  }

  get email(): string {
    return this.onboardingData.email;
  }

  // GOAL
  set goal(value: string) {
    this.onboardingData.goal = value;
  }

  get goal(): string {
    return this.onboardingData.goal;
  }

  set goalByWhen(value: string) {
    this.onboardingData.goal_by_when = value;
  }

  get goalByWhen(): string {
    return this.onboardingData.goal_by_when;
  }

  // INVESTING
  set investing_knowledge(value: string) {
    this.onboardingData.investing_knowledge = value;
  }

  get investing_knowledge(): string {
    return this.onboardingData.investing_knowledge;
  }

  // CONCERN
  set concern(value: string) {
    this.onboardingData.concern = value;
  }

  get concern(): string {
    return this.onboardingData.concern;
  }

  // VALUE DROP
  set valueDrop(value: string) {
    this.onboardingData.value_drop = value;
  }

  get valueDrop(): string {
    return this.onboardingData.value_drop;
  }

  // INVESTMENT SUMMARY
  set investmentSummary(value: string) {
    this.onboardingData.investment_summary = value;
  }

  get investmentSummary(): string {
    return this.onboardingData.investment_summary;
  }

  // ETHICS NUCLEAR POWER
  set sustainability(value: string) {
    this.onboardingData.sustainability = value;
  }

  get sustainability(): string {
    return this.onboardingData.sustainability;
  }


  // ETHICS FOSSIL FUELS
  set ethicsNuclearPower(value: EthicsExposureDecision) {
    this.onboardingData.ethics_nuclear_power = value;
  }

  get ethicsNuclearPower(): EthicsExposureDecision {
    return this.onboardingData.ethics_nuclear_power;
  }

  // ETHICS CIVILIAN WEAPONS
  set ethicsCivilianWeapons(value: EthicsExposureDecision) {
    this.onboardingData.ethics_civilian_weapons = value;
  }

  get ethicsCivilianWeapons(): EthicsExposureDecision {
    return this.onboardingData.ethics_civilian_weapons;
  }

  // ETHICS GAMBLING
  set ethicsGambling(value: EthicsExposureDecision) {
    this.onboardingData.ethics_gambling = value;
  }

  get ethicsGambling(): EthicsExposureDecision {
    return this.onboardingData.ethics_gambling;
  }

  // ETHICS Alcohol
  set ethicsAlcohol(value: EthicsExposureDecision) {
    this.onboardingData.ethics_alcohol = value;
  }

  get ethicsAlcohol(): EthicsExposureDecision {
    return this.onboardingData.ethics_alcohol;
  }

  // ETHICS ANIMAL TESTING
  set ethicsAnimalTesting(value: EthicsExposureDecision) {
    this.onboardingData.ethics_animal_testing = value;
  }

  get ethicsAnimalTesting(): EthicsExposureDecision {
    return this.onboardingData.ethics_animal_testing;
  }

  // ETHICS WEAPONS OF WAR
  set ethicsWeaponsOfWar(value: EthicsExposureDecision) {
    this.onboardingData.ethics_weapons_of_war = value;
  }

  get ethicsWeaponsOfWar(): EthicsExposureDecision {
    return this.onboardingData.ethics_weapons_of_war;
  }

  // ETHICS ADULT ENTERTAINMENT
  set ethicsAdultEntertainment(value: EthicsExposureDecision) {
    this.onboardingData.ethics_adult_entertainment = value;
  }

  get ethicsAdultEntertainment(): EthicsExposureDecision {
    return this.onboardingData.ethics_adult_entertainment;
  }

  // ETHICS TOBACCO
  set ethicsTobacco(value: EthicsExposureDecision) {
    this.onboardingData.ethics_tobacco = value;
  }

  get ethicsTobacco(): EthicsExposureDecision {
    return this.onboardingData.ethics_tobacco;
  }

  //
  // Getters and setters
  //

  get fund_id(): string {
    return this.onboardingData.fund_id;
  }

  set fund_id(value: string) {
    this.onboardingData.fund_id = value;
  }

  get dob(): string {
    return this.onboardingData.dob;
  }

  set dob(value: string) {
    this.onboardingData.dob = value;
  }

  get pir(): string {
    return this.onboardingData.pir;
  }

  set pir(value: string) {
    this.onboardingData.pir = value;
  }

  get addressLine1(): string {
    return this.onboardingData.address_l1;
  }

  set addressLine1(value: string) {
    this.onboardingData.address_l1 = value;
  }

  get addressLine2(): string {
    return this.onboardingData.address_l2;
  }

  set addressLine2(value: string) {
    this.onboardingData.address_l2 = value;
  }

  get city(): string {
    return this.onboardingData.city;
  }

  set city(value: string) {
    this.onboardingData.city = value;
  }

  get postCode(): string {
    return this.onboardingData.post_code;
  }

  set postCode(value: string) {
    this.onboardingData.post_code = value;
  }

  get irdNumber(): string {
    return this.onboardingData.ird_number;
  }

  set irdNumber(value: string) {
    this.onboardingData.ird_number = value;
  }

  get complete(): boolean {
    return this.onboardingData.signupcomplete;
  }

  set complete(value: boolean) {
    this.onboardingData.signupcomplete = value;
  }

  get passport_number(): string {
    return this.onboardingData.passport_number;
  }

  set passport_number(value: string) {
    this.onboardingData.passport_number = value;
  }

  get passport_expiry(): string {
    return this.onboardingData.passport_expiry;
  }

  set passport_expiry(value: string) {
    this.onboardingData.passport_expiry = value;
  }

  get license_number(): string {
    return this.onboardingData.license_number;
  }

  set license_number(value: string) {
    this.onboardingData.license_number = value;
  }

  get license_version(): string {
    return this.onboardingData.license_version;
  }

  set license_version(value: string) {
    this.onboardingData.license_version = value;
  }

  get license_expiry(): string {
    return this.onboardingData.license_expiry;
  }

  set license_expiry(value: string) {
    this.onboardingData.license_expiry = value;
  }

  get cloudCheckCaptureReference(): string {
    return this.onboardingData.cloud_check_capture_reference;
  }

  set cloudCheckCaptureReference(value: string) {
    this.onboardingData.cloud_check_capture_reference = value;
  }

  get hear_about_us(): string {
    return this.onboardingData.hear_about_us;
  }

  set hear_about_us(value: string) {
    this.onboardingData.hear_about_us = value;
  }

  get signup_error(): string {
    return this.onboardingData.signup_error;
  }

  set signup_error(value: string) {
    this.onboardingData.signup_error = value;
  }

  get created_by(): string {
    return this.onboardingData.created_by;
  }

  set created_by(value: string) {
    this.onboardingData.created_by = value;
  }

  get milford_accepted(): boolean {
    return this.onboardingData.milford_accepted;
  }

  set milford_accepted(value: boolean) {
    this.onboardingData.milford_accepted = value;
  }

  get promo_code(): string {
    return this.onboardingData.promo_code;
  }

  set promo_code(value: string) {
    this.onboardingData.promo_code = value;
  }

  async loadData(userId: string): Promise<OnboardingData> {
    return this.loadOnboardingData(userId);
  }

  async create(userId: string) {
    const collection = this.firestore.col(this.onBoardingPath).doc(userId);
    this.onboardingData.id = userId;
    this._onboardingId = userId;
    await this.firestore.upsert(collection, this.onboardingData);
  }

  async save() {
    const document = this.onboardingDoc;
    this.updateIntercomData();
    await this.firestore.upsert(document, this.onboardingData);
  }

  async getByEmail(email: string): Promise<OnboardingData[]> {
    const person = await this.col.ref.where('email', '==', email).get();
    return person.docs.map((docData) => {
      const d = docData.data() as OnboardingData;
      d.id = docData.id;
      return d;
    });
  }

  async getByPhone(phone: string): Promise<OnboardingData[]> {
    const person = await this.col.ref.where('phone', '==', phone).get();
    return person.docs.map((docData) => {
      const d = docData.data() as OnboardingData;
      d.id = docData.id;
      return d;
    });
  }
}
