

export class NotFoundException {

  message: string;
  constructor(message: string = '' ) {
    this.message = message;
  }

  toString() {
    console.log(`NotFoundException ${this.message}`);
  }


}
