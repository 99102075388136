import { ApplicationRef, Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  Data,
  Event,
  NavigationEnd,
  Router,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { ConnectionService } from '@app-shared/connection.service';
import { environment } from '@app-env/environment';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isConnected = true;

  constructor(
    private router: Router,
    a: ApplicationRef,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private connectionService: ConnectionService,
    private firebaseAnalytics: AngularFireAnalytics,
    private gtmService: GoogleTagManagerService,
    private cookieService: CookieService
  ) {
    this.connectionService.updateOptions({ enableHeartbeat: false });

    this.connectionService.monitor().subscribe((connectedState) => {
      this.isConnected = connectedState.hasNetworkConnection;
    });

    const navEndEvent$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: Event) => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data: Data) => {
          console.log('event', event);
          console.log('data', data);

          this.titleService.setTitle(`${data['title']} | BetterSaver`);

          // firebase analytics - decided to use GTM instead of firbease analytics... for now...
          // this.firebaseAnalytics.logEvent('page_view', {
          //   screen_name: data.title,
          //   window_height: window.innerHeight,
          //   window_width: window.innerWidth
          // });

          if (environment.production) {

            // google GTM
            const gtmTag = {
              event: 'page_view',
              page: {
                page_location: `${window.location.origin}${(event as NavigationEnd).url}`,
                page_title: data['title'],
                environment: environment.production ? 'production' : 'staging',
              },
              user: {
                user_id:
                  this.cookieService.get('uid') != ''
                    ? this.cookieService.get('uid')
                    : undefined,
                hs_user_id:
                  this.cookieService.get('hsid') != ''
                    ? this.cookieService.get('hsid')
                    : undefined,
                saving_personality:
                  this.cookieService.get('saving_personality') != ''
                    ? this.cookieService.get('saving_personality')
                    : undefined,
                recommended_fund:
                  this.cookieService.get('recommended_fund') != ''
                    ? this.cookieService.get('recommended_fund')
                    : undefined,
              },
              window: {
                height: window.innerHeight,
                width: window.innerWidth,
              },
            };
            console.log('gtmTag', gtmTag);
            this.gtmService.pushTag(gtmTag);

          } // end prod check  if
        });
      });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
