<div class="component-outer-wrapper">
  <div class="component-container landing" ngClass.md="tablet" fxLayout="column">

    <div class="header"
         fxLayout="row"
         fxLayoutAlign="center center"
    >

        <img id="logo" alt="BetterSaver Logo" src="../../assets/images/cache/BetterSaver.svg">

    </div>

    <div class="content-container" fxLayout="column">
      <h2>Well&nbsp;<span>hello</span>&nbsp;<wbr>there.</h2>

      <p>First time here?</p>
      <a [routerLink]="'/kiwisaver/onboarding'">signup</a>


      <p>Already registered?</p>
      <a [routerLink]="'/login'">login</a>

    </div>
  </div>
</div>

