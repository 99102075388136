import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { AppBasePreloader } from './app-preload-strategy';
import { DashboardGuard } from './dashboard/dashboard.guard';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    data: {
      title: 'Landing',
    },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: {
      shouldPreload: false,
      title: 'Dashboard',
    },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: {
      title: 'Admin',
    },
  },
  {
    // a: LogoutModule
    path: 'logout',
    loadChildren: () =>
      import('./logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    // a: LoginModule
    path: 'login',
    data: {
      shouldPreload: true,
      title: 'Login',
    },
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },

  {
    // a:
    path: 'kiwisaver/onboarding',
    data: {
      shouldPreload: true,
      title: 'Login',
    },
    loadChildren: () =>
      import('./ks-onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
  },

  {
    // a: ResetPasswordModule
    path: 'reset-password',
    data: {
      title: 'Reset Password',
    },
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'signup',
    redirectTo: 'kiwisaver/onboarding',
  },
  {
    path: 'kiwisaver/onboarding',
    redirectTo: 'kiwisaver/onboarding',
  },
  {
    path: '404',
    data: {
      title: '404 Not Found'
    },
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
      preloadingStrategy: AppBasePreloader,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
