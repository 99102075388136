import {Injectable} from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {PersonService} from './services/person/person.service';
import {OnboardingData, OnboardingService} from './services/onboarding/onboarding.service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationMediatorService {


  constructor(
    private authService: AuthService,
    private personService: PersonService,
    private onboardingService: OnboardingService,
  ) {

    // this.authService.authenticatedUserBS.subscribe(async (user) => {
    //
    //   debugger
    //   //
    //   // if (user != null) {
    //   //
    //   //   // if the user is not anoumous then load the profile
    //   //   if (!user.isAnonymous) {
    //   //     try {
    //   //       const p = await this.personService.loadAuthenticatedPerson(user.id);
    //   //       console.log(p);
    //   //     } catch (e) {
    //   //       console.log(e);
    //   //     }
    //   //
    //   //   } else {
    //   //
    //   //
    //   //   }
    //   //
    //   //
    //   // } else {
    //   //   await this.personService.clearAuthticatedPerson();
    //   // }
    // });

  }


}
