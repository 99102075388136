export enum AuthExceptionCode {
  invalidEmail,
  userDisabled,
  userNotFound,
  wrongPassword,
  invalidActionCode,
  expiredActionCode,
  userAlreadyExists,
  notAdmin,
  notPortal,
  unknown
}

export class AuthException {

  type: AuthExceptionCode = AuthExceptionCode.unknown;

  get message() {

    switch (this.type) {
      case AuthExceptionCode.invalidEmail:
        return 'The email is invalid';

      case AuthExceptionCode.userDisabled:
        return 'The user with this email is disabled';

      case AuthExceptionCode.userNotFound:
        return 'No user was found with this email';

      case AuthExceptionCode.wrongPassword:
        return 'The password is incorrect';

      case AuthExceptionCode.invalidActionCode:
        return 'Link is invalid. This can happen if the link is malformed, expired, or has already been used.';

      case AuthExceptionCode.expiredActionCode:
        return 'Link is expired';

      case AuthExceptionCode.userAlreadyExists:
        return 'User already exists';

      case AuthExceptionCode.notAdmin:
        return 'Insufficient privileges';

      case AuthExceptionCode.notPortal:
        return 'Insufficient privileges';

      case AuthExceptionCode.unknown:
        return 'Insufficient privileges';
    }

  }

}
