// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: 'AIzaSyCBjG_KWt1ofh-eFSGjPMLxc7NDHEuunNw',
    authDomain: 'bettersaver-dev.firebaseapp.com',
    databaseURL: 'https://bettersaver-dev.firebaseio.com',
    projectId: 'bettersaver-dev',
    storageBucket: 'bettersaver-dev.appspot.com',
    messagingSenderId: '597293639021',
    appId: '1:597293639021:web:e358dcb570318d938de664',
    measurementId: 'G-6HF9VB9RNY' // using GTM instead
  },


  systemApi: {
    baseURL: 'https://bettersaver-system-api-sz3lcgistq-uc.a.run.app',
    paths: {
      cloudCheck: {
        verifyPath: '/verify/cloudcheck/generate-url',
        tokenExchangePath: '/verify/cloudcheck/token-exchange',
        verifyPathIntergrated: '/verify/cloudcheck/integrated',
      },
      launchToken: {
        checkPath: '/autopilot/token/check',
        burnPath: '/autopilot/token/burn'
      },
      intercom: {
        contactUpdatePath: '/intercom/contact/update',
      },
      email: {
        deliverability: '/email/deliverability',
        sendTermsAndConditions: '/email/send-terms-and-conditions',
      },
      admin: {
        updateEmail: '/admin/user/email/update',
        createUser: '/admin/user/create',
      },
      engine: {
        recommendation: '/engine/recommendation/v1',
        riskCalculator: '/engine/risk-calculator/v1',
      }
    }
  },

  cloudCheckModule: {
    callBackURL: 'https://dev.bettersaver.co.nz/kiwisaver/onboarding/flow/verify/success',
    nextPath: '/kiwisaver/onboarding/flow/review-details'
  },

  intercom: {
    id: 'gt21r4a8',
  },

  segmentTrackingEnabled: false,

  analytics: {
    debugMode: true
  },

  google: {
    gtmCode: 'GTM-NTNKBMS'
  },

  sentry: {
    dsn: 'https://35f09dae8ce842f085918892fba74c6d@o994747.ingest.sentry.io/5953399',
    environment: 'dev',
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
