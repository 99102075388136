import {BrowserModule, HammerModule, Title} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@app-env/environment';
import {FlexLayoutModule} from '@angular/flex-layout';
import {Router, RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule, SETTINGS} from '@angular/fire/compat/firestore';
import {ApplicationMediatorService} from '@app-shared/application-mediator.service';
import {AuthService} from '@app-shared/services/auth/auth.service';

import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {LandingComponent} from './landing/landing.component';
import {AngularFireAnalyticsModule, DEBUG_MODE} from '@angular/fire/compat/analytics';
import {HttpClientModule} from '@angular/common/http';
import {Intercom, IntercomModule} from 'ng-intercom';
import {CookieService} from 'ngx-cookie-service';

import 'hammerjs';
import * as Sentry from '@sentry/angular';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { FirestoreService } from '@app-shared/services/firestore.service';
import { NotFoundComponent } from './not-found/not-found.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotFoundComponent,
  ],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule,

    HammerModule,

    // 3rd party Modules
    FlexLayoutModule,
    NgxChartsModule,

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,

    IntercomModule.forRoot({
      appId: environment.intercom.id, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),

    AppRoutingModule,

    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
  ],
  providers: [
    CookieService,
    Title,
    {provide: DEBUG_MODE, useValue: environment.analytics.debugMode},
    {provide: 'googleTagManagerId', useValue: environment.google.gtmCode},
    {provide: ErrorHandler, useValue: Sentry.createErrorHandler({
      showDialog: false,
    })},
    {provide: Sentry.TraceService, deps: [Router]},
    {provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'en-NZ'},
    {provide: SETTINGS, useValue: {experimentalAutoDetectLongPolling: true}},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  // build and start all the service we need straight away
  constructor(
    private firestore: FirestoreService,
    private authService: AuthService,
    private applicationMediatorService: ApplicationMediatorService,
    private intercom: Intercom,
  ) {

    this.intercom.boot({
      app_id: environment.intercom.id,
    });

  }
}
