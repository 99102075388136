import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { FirestoreService } from '../firestore.service';
import { BehaviorSubject } from 'rxjs';
import { NotFoundException } from '../not-found-exception';

export enum PersonStatus {
  // Person is unknown so doesn’t have an account/we don’t have an email on their person record.
  // This should only be a status in firestore Person table and will not surface up to hubspot.
  anonymous = 'anonymous',
  // Never had a product/account that went into pending/ready/limbo/active.
  // If they have an account/product then it is shown as 'Onboarding'
  browsing = 'browsing',
  // Has no product in an 'active' state but does have it in either 'pending', 'ready', or 'limbo'
  pending = 'pending',
  // Person has at least one product with an 'active' state
  active = 'active',
  // No longer has an 'active' product and their most recent history shows their product achieved their 'goal'.
  // No $ in any of their funds.
  dormant = 'dormant',
  // No longer has an 'active' product but their most recent history shows their product was 'cancelled'
  cancelledAfter = 'cancelled_after',
  // May have reached either the stages 'pending', 'ready', 'limbo' but cancelled before reaching 'active'
  cancelledBefore = 'cancelled_before',
}

export enum ContactState {
  onboarding = 'onboarding',
  pending = 'pending',
  verified = 'verified',
}

export interface Person {
  id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  onboarding_id: string;
  pir: string;
  phone: string;
  address_line1: string;
  address_line2: string;
  post_code: string;
  city: string;
  ird_number: string;
  updated_at: Date;
  created_at: Date;
  dob: string;
  license_number: string;
  license_version: string;
  license_expiry: string;
  passport_number: string;
  passport_expiry: string;
  state: ContactState;
  person_status: PersonStatus;
  cloud_check_capture_reference: string;
  sv_cloud_check_integrated_id: string;
  sv_hubspot_id: string;
  cloud_check_verification: boolean;
  referer_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  private collectionPath = 'Person';
  private col: AngularFirestoreCollection;

  authenticatedPersonBS = new BehaviorSubject<Person>({} as Person);

  constructor(
    private firestoreService: FirestoreService,
  ) {
    this.col = this.firestoreService.col(this.collectionPath);
  }

  async create(person: Person, userId?: string): Promise<Person> {

    if (userId === undefined) {
      userId = this.firestoreService.generateId;
    }

    person.id = userId;
    person.state = ContactState.pending;
    return this.upsert(person, userId);

  }

  async upsert(person: Person, userId: string): Promise<Person> {
    const doc = this.col.doc(userId);
    await this.firestoreService.upsert(doc, person);

    person.id = userId;

    const personData = await this.getOne(person.id);

    if (personData == null) {
      throw new Error();
    } else {
      return personData;
    }
  }

  async getAll(): Promise<Person[]> {
    const results = await this.col.ref.get();

    if (results.empty) {
      throw new NotFoundException(`No Accounts found`);
    } else {
      return results.docs.map(d => d.data() as Person);
    }
  }

  async getOne(id: string): Promise<Person> {
    return await this.firestoreService.docOnce<Person>(this.col.doc(id));
  }

  async getByEmail(email: string): Promise<Person[]> {
    const person = await this.col.ref.where('email', '==', email).get();
    return person.docs.map((docData) => {
      const d = docData.data() as Person;
      d.id = docData.id;
      return d;
    });
  }

  async getByIrd(ird: string): Promise<Person[]> {
    const person = await this.col.ref.where('ird_number', '==', ird).get();
    return person.docs.map((docData) => {
      const d = docData.data() as Person;
      d.id = docData.id;
      return d;
    });
  }

  async getByPhone(phone: string): Promise<Person[]> {
    const person = await this.col.ref.where('phone', '==', phone).get();
    return person.docs.map((docData) => {
      const d = docData.data() as Person;
      d.id = docData.id;
      return d;
    });
  }

}
